<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách vùng'">
          <template v-slot:toolbar>
            <div class="row">
              <b-button
                v-show="checkPermission('REGION_INSERT')"
                variant="primary"
                size="sm"
                @click="showModalAddCompany"
                class="btn btn-primary font-weight-bolder btn-sm"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
              </b-button>
            </div>
          </template>
          <template v-slot:preview>
            <div class="col-md-12 mb-5">
              <b-form>
                <div class="row">
                  <div class="col-md-8">
                    <div class="row">
                      <b-input
                        placeholder="Tìm kiếm theo tên"
                        v-model="searchName"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-4 mr-4"
                        @keyup="onSearch"
                        v-on:keyup.enter="onSearch"
                        size="sm"
                      ></b-input>
                      <b-input
                        placeholder="Tìm kiếm theo mã"
                        v-model="searchCode"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-4"
                        @keyup="onSearch"
                        v-on:keyup.enter="onSearch"
                        size="sm"
                      ></b-input>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
            <b-modal ref="add-company-modal" hide-footer title="Thêm mới vùng">
              <v-form ref="form" lazy-validation>
                <div class="w-50">
                  <b-form-group
                    id="input-group-2"
                    label-for="input-2"
                    aria-describedby="input-2-live-feedback"
                  >
                    <template>
                      <span>Tên:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-2"
                      type="name"
                      v-model="name"
                      required
                      placeholder="Nhập tên"
                      size="sm"
                      :state="validateState('name')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.name.required"
                      id="input-2-live-feedback"
                      >Yêu cầu nhập tên vùng</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group
                    id="input-group-3"
                    label-for="input-3"
                    aria-describedby="input-2-live-feedback"
                  >
                    <template>
                      <span>Mã:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-3"
                      type="name"
                      v-model="code"
                      required
                      placeholder="Nhập mã vùng"
                      size="sm"
                      :state="validateState('code')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.code.required"
                      id="input-3-live-feedback"
                      >Yêu cầu nhập mã vùng</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group
                    id="input-group-3"
                    label="Trạng thái:"
                    label-for="input-3"
                  >
                    <b-form-select
                      id="input-1"
                      v-model="selectedStatus"
                      :options="listStatus"
                      required
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >-- Chọn trạng thái --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  v-show="checkPermission('REGION_INSERT')"
                  @click="createRegion"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalAddCompany"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-modal
              ref="update-company-modal"
              hide-footer
              title="Cập nhật vùng"
            >
              <v-form ref="form" lazy-validation>
                <div class="w-50">
                  <b-form-group
                    id="input-group-1"
                    label-for="input-2"
                    aria-describedby="input-2-live-feedback"
                  >
                    <template>
                      <span>Tên:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-2"
                      type="name"
                      v-model="name"
                      required
                      placeholder="Nhập tên"
                      size="sm"
                      :state="validateState('name')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.name.required"
                      id="input-2-live-feedback"
                      >Yêu cầu nhập tên vùng</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group
                    id="input-group-3"
                    label-for="input-3"
                    aria-describedby="input-3-live-feedback"
                  >
                    <template>
                      <span>Mã:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-3"
                      type="name"
                      v-model="code"
                      required
                      placeholder="Nhập mã vùng"
                      size="sm"
                      :state="validateState('code')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.code.required"
                      id="input-3-live-feedback"
                      >Yêu cầu nhập mã vùng</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group
                    id="input-group-3"
                    label="Trạng thái:"
                    label-for="input-3"
                  >
                    <b-form-select
                      id="input-1"
                      v-model="selectedStatus"
                      :options="listStatus"
                      required
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >-- Chọn trạng thái --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateRegion"
                  v-show="checkPermission('REGION_UPDATE')"
                  >Lưu</b-button
                >
                <b-button
                  v-if="checkPermission('REGION_UPDATE')"
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalUpdateCompany"
                  >Hủy</b-button
                >
                <b-button
                  v-if="!checkPermission('REGION_UPDATE')"
                  style="font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalUpdateCompany"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-table
              :items="listItem"
              :fields="fields"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="4"></vcl-table>
              </template>
              <template v-slot:cell(stt)="row">
                <div class="d-flex justify-content-center w-6">
                  <span v-text="row.item.stt"></span>
                </div>
              </template>
              <template v-slot:cell(status)="row">
                <span
                  v-if="row.item.status === 1"
                  v-text="'Hoạt động'"
                  class="label font-weight-bold label-lg label-light-success label-inline"
                ></span>
                <span
                  v-if="row.item.status === 0"
                  v-text="'Đã khóa'"
                  class="label font-weight-bold label-lg label-light-danger label-inline"
                ></span>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 10px" class="flaticon-eye"></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="!checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-show="checkPermission('REGION_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số vùng:
                  {{ totalRow }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  v-show="numberOfPage >= 2"
                  class="custom-pagination"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-1 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1"
                  last-class="page-item-last btn btn-icon btn-sm my-1"
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                ></b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<style scoped>
.icon {
  padding-top: 7px;
}
.ml-10 {
  margin-left: 5px;
}
.ml-20 {
  margin-left: 10px;
}
.ml-30 {
  margin-left: 15px;
}
.ml-40 {
  margin-left: 20px;
}
.ml-50 {
  margin-left: 25px;
}

.sttCateClass {
  width: 6%;
}
.nameCateClass {
  width: 45%;
}
.codeCateClass {
  width: 45%;
}

.icon:hover {
  background-color: #90c6fc;
}

.container-fluid {
  height: 100%;
  display: table;
  width: 100%;
  padding: 0;
}

.row-fluid {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.centering {
  float: none;
  margin: 0 auto;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '../../../utils/saveDataToLocal';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { VclTable } from 'vue-content-loading';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  data() {
    return {
      searchName: '',
      searchCode: '',
      fields: [
        {
          key: 'stt',
          label: 'STT',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%'
          },
          tdClass: 'sttCateClass',
          thClass: 'sttCateClass'
        },
        {
          key: 'name',
          label: 'Tên vùng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '40%' }
        },
        {
          key: 'code',
          label: 'Mã vùng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '40%' }
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' }
        },
        { key: 'actions', label: '' }
      ],
      listItem: [],
      linkGen: pageNum => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: null,
      count: 1,
      listStatus: [
        {
          id: 0,
          name: 'Đã khóa'
        },
        {
          id: 1,
          name: 'Hoạt động'
        }
      ],
      selectedStatus: 1,
      name: '',
      code: '',
      valid: true,
      companyId: 0,
      totalRow: 0,
      onLoading: false,
      submitted: false,
      isNew: true
    };
  },
  components: {
    KTCodePreview,
    VclTable
  },
  validations: {
    name: {
      required
    },
    code: {
      required
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Vùng', route: '/region' },
      { title: 'Danh sách vùng' }
    ]);
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    onSearch() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-region'
      });
      this.fetchData();
    },
    fetchData: async function() {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let param = {
        page: this.page,
        limit: 10,
        name: this.searchName,
        code: this.searchCode
      };
      let paramQuery = {
        params: param
      };
      this.onLoading = true;
      ApiService.setHeader();
      ApiService.query('region/list', paramQuery)
        .then(({ data }) => {
          if (this.page === 1) {
            this.count = 1;
          } else {
            this.count = 1 + (this.page - 1) * 10;
          }
          this.count = 1;
          this.totalRow = data.data.total_row;
          this.numberOfPage = data.data.total_page;
          this.listItem = [];
          data.data.list_region.forEach(element => {
            let item = {
              id: element.id,
              stt: this.count,
              name: element.name,
              code: element.code,
              status: element.status
            };
            this.listItem.push(item);
            this.count++;
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function() {
      let count = 0;
      if (localData.checkPermission('REGION_VIEW')) {
        count++;
      }
      if (localData.checkPermission('REGION_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true
      });
    },
    deleteItem: async function(item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.delete(`region/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    showDeleteAlert: function(item) {
      Swal.fire({
        title: 'Xóa vùng!',
        text: 'Bạn có chắc muốn xóa vùng này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        position: 'top'
      }).then(result => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    editItem: function(item) {
      this.companyId = item.id;
      this.getRegionById();
      this.showModalUpdateCompany();
    },
    getRegionById: function() {
      ApiService.setHeader();
      ApiService.get(`region/${this.companyId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.name = data.data.name;
            this.code = data.data.code;
            this.selectedStatus = data.data.status;
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    showModalAddCompany() {
      this.isNew = true;
      this.selectedStatus = 1;
      this.name = '';
      this.code = '';
      this.$refs['add-company-modal'].show();
    },
    hideModalAddCompany() {
      this.$refs['add-company-modal'].hide();
    },
    showModalUpdateCompany() {
      this.isNew = true;
      this.$refs['update-company-modal'].show();
    },
    hideModalUpdateCompany() {
      this.$refs['update-company-modal'].hide();
    },
    createRegion: function() {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const name = this.name;
      const code = this.code;
      let data = {
        code: code,
        name: name,
        status: this.selectedStatus
      };
      ApiService.setHeader();
      ApiService.post('region/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.hideModalAddCompany();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    updateRegion: async function() {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const name = this.name;
      const code = this.code;
      let data = {
        id: this.companyId,
        name: name,
        code: code,
        status: this.selectedStatus
      };
      ApiService.setHeader();
      ApiService.put('region/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.hideModalUpdateCompany();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    }
  }
};
</script>
